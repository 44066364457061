import React, { FC } from 'react';

import { Template } from '@/components/common/Template';

import Image from 'next/image';

export const NotFound: FC = () => {
  return (
    <Template>
      <section>
        <div className="w-full h-max flex items-center justify-center flex-col">
          <div className=" w-[560px] h-max ">
            <Image
              src="/notfound.svg"
              title="Icone de página não encontrada"
              alt="Icone de página não encontrada"
              className="w-max h-max"
              width={24}
              height={24}
            />
          </div>
          <div className="lg:w-[500px] w-[100%] flex gap-5 flex-col">
            Ops! Parece que você se perdeu. A página que está procurando não foi
            encontrada.
          </div>
        </div>
      </section>
    </Template>
  );
};

export default NotFound;
